import {enTranslates} from "./enTranslates";
import {ruTranslates} from "./ruTranslates";
import {useAppSelector} from "../../../../../store/store";
import {Translates} from "./translates";

export const useTranslate = (): Translates => {
    const lang = useAppSelector(state => state.appReducer.projectInfo.language)

    switch (lang) {
        case 'EN':
            return enTranslates
        case 'RU':
            return ruTranslates
        default:
            return enTranslates
    }
}