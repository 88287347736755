import React, {FC} from 'react';

interface CollapseIconProps {
    isCollapsed: boolean
}

export const CollapseIcon: FC<CollapseIconProps> = (props) => {
    const { isCollapsed } = props

    return (
        <svg
            height="1em"
            viewBox="0 0 448 512"
            style={{
                transition: 'transform .2s',
                transform: isCollapsed ? 'unset' : 'rotate(180deg)'
            }}
        >
            <path d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z" fill="black"/>
        </svg>
    );
};