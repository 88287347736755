// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoBlockStyles_infoBlock__B0zdb {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n}\n\n.InfoBlockStyles_header__gkmPe {\n    font-weight: normal;\n    font-size: 0.9rem;\n}\n\n.InfoBlockStyles_content__bmoUs {\n    display: flex;\n    gap: 1.5rem;\n}\n\n.InfoBlockStyles_defaultTextStyles__iHSwN {\n    font-size: 0.6rem;\n    width: 8rem;\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/InfoBlock/InfoBlockStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".infoBlock {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n}\n\n.header {\n    font-weight: normal;\n    font-size: 0.9rem;\n}\n\n.content {\n    display: flex;\n    gap: 1.5rem;\n}\n\n.defaultTextStyles {\n    font-size: 0.6rem;\n    width: 8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlock": "InfoBlockStyles_infoBlock__B0zdb",
	"header": "InfoBlockStyles_header__gkmPe",
	"content": "InfoBlockStyles_content__bmoUs",
	"defaultTextStyles": "InfoBlockStyles_defaultTextStyles__iHSwN"
};
export default ___CSS_LOADER_EXPORT___;
