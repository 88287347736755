import {$host} from "../../../../axios";
import {Language} from "../../../../store/appReducer/appReducerTypes";

export const registerUser = async (token: string, name: string): Promise<string> => {
    const response = await $host.post('/login/', { token, name })
    return response.data
}

export const checkUser = async (token: string): Promise<string | Language> => {
    const response = await $host.get(`/login/check?token=${token}`)
    return response.data
}