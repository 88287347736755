import React, {FC} from 'react';

interface MinusIconProps {
    stroke?: string
}

export const MinusIcon: FC<MinusIconProps> = (props) => {
    const { stroke = '#323232' } = props

    return (
        <svg width="1.125rem" height="0.125rem" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3307 1H1.66406" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};