import React, {FC} from 'react';

interface ArrowBackIconProps {
    className?: string
}

export const ArrowBackIcon: FC<ArrowBackIconProps> = (props) => {
    const { className } = props

    return (
        <svg width="0.75rem" height="0.75rem" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M0.999912 4.375H6.55547C7.73421 4.375 8.86466 4.84911 9.69813 5.69302C10.5316 6.53693 10.9998 7.68153 10.9998 8.875L10.9998 10M0.999912 4.375L4.33316 7.75M0.999912 4.375L4.33333 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};