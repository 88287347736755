import axios from "axios";


export const api = process.env.REACT_APP_API_URL || 'http://localhost:5000/'

const $authHost = axios.create({
    baseURL: api
})

const $host = axios.create({
    baseURL: api
})

const authInterceptor = (config: any) => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

const check = async () => {
    const {data} = await $authHost.get('login/auth/user')
    return data
}

export {
    check,
    $authHost,
    $host
}