// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthStyles_authBlock__OBPFA {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2rem;\n    padding: 3rem 5rem;\n    border: 0.0625rem solid rgba(50, 50, 50, 0.2);\n    border-radius: 1rem;\n}\n\n.AuthStyles_label__gm5Rl {\n    text-align: center;\n    font-size: 1.2rem;\n}\n\n.AuthStyles_inputContainer__YWY2C {\n    gap: 2rem\n}", "",{"version":3,"sources":["webpack://./src/project/modules/common/Auth/AuthStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,6CAA6C;IAC7C,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI;AACJ","sourcesContent":[".authBlock {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2rem;\n    padding: 3rem 5rem;\n    border: 0.0625rem solid rgba(50, 50, 50, 0.2);\n    border-radius: 1rem;\n}\n\n.label {\n    text-align: center;\n    font-size: 1.2rem;\n}\n\n.inputContainer {\n    gap: 2rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authBlock": "AuthStyles_authBlock__OBPFA",
	"label": "AuthStyles_label__gm5Rl",
	"inputContainer": "AuthStyles_inputContainer__YWY2C"
};
export default ___CSS_LOADER_EXPORT___;
