// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconTextButtonStyles_addButton__GZ-F9 {\n    background-color: white;\n    min-height: 2.75rem;\n    border: 0.0625rem solid;\n    border-radius: 0.625rem;\n    display: inline-flex;\n    align-items: center;\n    padding: 0.625rem 0.9375rem;\n    cursor: pointer;\n}\n\n.IconTextButtonStyles_addButton__GZ-F9 span {\n    font-size: 1rem;\n    color: #A8A8A8;\n    margin-left: 0.625rem;\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/IconTextButton/IconTextButtonStyles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,oBAAoB;IACpB,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".addButton {\n    background-color: white;\n    min-height: 2.75rem;\n    border: 0.0625rem solid;\n    border-radius: 0.625rem;\n    display: inline-flex;\n    align-items: center;\n    padding: 0.625rem 0.9375rem;\n    cursor: pointer;\n}\n\n.addButton span {\n    font-size: 1rem;\n    color: #A8A8A8;\n    margin-left: 0.625rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "IconTextButtonStyles_addButton__GZ-F9"
};
export default ___CSS_LOADER_EXPORT___;
