import React, {FC} from 'react';
import {InstructionBlock} from "../../../system/components";
import {useTranslate} from "./translates/useTranslate";
import {ErrorMessages} from "../../../../common/types";
interface ErrorMessageBlockProps {
    errorMessage: string | undefined
}

const useCorrectTranslates = (errorMessage: string | undefined) => {
    const translates = useTranslate()

    switch (errorMessage) {
        case (ErrorMessages.AUTHORIZATION_FAILED):
            return { errorTittle: translates.authorizationFailedTittle, errorText: translates.authorizationFailedText }
        case (ErrorMessages.ACCESS_DENIED):
            return { errorTittle: translates.noAccessTittle, errorText: translates.noAccessText }
        case (ErrorMessages.NO_ACCESS):
            return { errorTittle: translates.noAccessTittle, errorText: translates.noAccessText }
        default:
            return { errorTittle: translates.somethingsWrongTittle, errorText: translates.somethingsWrongText }
    }
}

export const ErrorMessageBlock: FC<ErrorMessageBlockProps> = (props) => {
    const { errorMessage } = props
    const { errorTittle, errorText } = useCorrectTranslates(errorMessage)

    return (
        <InstructionBlock
            title={errorTittle}
            content={errorText}
        />
    );
};