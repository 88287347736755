import React, {FC} from 'react';

interface TrashIconProps {
    className?: string
}

export const TrashIcon: FC<TrashIconProps> = (props) => {
    const { className } = props

    return (
        <svg width="1rem" height="1.375rem" viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.90882 0.492188C6.70571 0.492319 6.50665 0.561054 6.33393 0.690693C6.1612 0.820332 6.02163 1.00576 5.93084 1.2262L5.13883 3.14685H1.43915C1.14902 3.14685 0.870773 3.28669 0.665621 3.53561C0.460468 3.78454 0.345215 4.12215 0.345215 4.47418C0.345215 4.82621 0.460468 5.16382 0.665621 5.41274C0.870773 5.66167 1.14902 5.80151 1.43915 5.80151V19.0748C1.43915 19.7789 1.66966 20.4541 2.07996 20.9519C2.49026 21.4498 3.04676 21.7295 3.62702 21.7295H12.3785C12.9587 21.7295 13.5152 21.4498 13.9255 20.9519C14.3358 20.4541 14.5664 19.7789 14.5664 19.0748V5.80151C14.8565 5.80151 15.1347 5.66167 15.3399 5.41274C15.545 5.16382 15.6603 4.82621 15.6603 4.47418C15.6603 4.12215 15.545 3.78454 15.3399 3.53561C15.1347 3.28669 14.8565 3.14685 14.5664 3.14685H10.8667L10.0747 1.2262C9.98387 1.00576 9.8443 0.820332 9.67157 0.690693C9.49885 0.561054 9.29979 0.492319 9.09668 0.492188H6.90882ZM4.72095 8.45617C4.72095 8.10414 4.8362 7.76653 5.04136 7.51761C5.24651 7.26868 5.52475 7.12884 5.81488 7.12884C6.10501 7.12884 6.38326 7.26868 6.58841 7.51761C6.79356 7.76653 6.90882 8.10414 6.90882 8.45617V16.4202C6.90882 16.7722 6.79356 17.1098 6.58841 17.3587C6.38326 17.6076 6.10501 17.7475 5.81488 17.7475C5.52475 17.7475 5.24651 17.6076 5.04136 17.3587C4.8362 17.1098 4.72095 16.7722 4.72095 16.4202V8.45617ZM10.1906 7.12884C9.90049 7.12884 9.62224 7.26868 9.41709 7.51761C9.21194 7.76653 9.09668 8.10414 9.09668 8.45617V16.4202C9.09668 16.7722 9.21194 17.1098 9.41709 17.3587C9.62224 17.6076 9.90049 17.7475 10.1906 17.7475C10.4807 17.7475 10.759 17.6076 10.9641 17.3587C11.1693 17.1098 11.2846 16.7722 11.2846 16.4202V8.45617C11.2846 8.10414 11.1693 7.76653 10.9641 7.51761C10.759 7.26868 10.4807 7.12884 10.1906 7.12884Z" fill={!className ? "black" : "unset"}/>
        </svg>
    );
};