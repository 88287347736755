// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HyperlinkStyles_link__hbneZ {\n    transition: color .2s;\n    color: black;\n}\n\n.HyperlinkStyles_link__hbneZ:hover {\n    color: #FFC000;\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/Link/HyperlinkStyles.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".link {\n    transition: color .2s;\n    color: black;\n}\n\n.link:hover {\n    color: #FFC000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "HyperlinkStyles_link__hbneZ"
};
export default ___CSS_LOADER_EXPORT___;
