// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressBarStyles_progressBarContainer__qRPGr {\n    display: flex;\n    align-items: center;\n    gap: 0.2rem;\n}\n\n.ProgressBarStyles_progressBar__ZGFin {\n    position: relative;\n    border-radius: 4px;\n    overflow: hidden;\n    background-color: white;\n    border: 2px solid;\n}\n\n.ProgressBarStyles_progressIndicator__8ZLjO {\n    height: 100%;\n    text-align: center;\n    font-weight: bold;\n    transition: width 0.3s;\n}\n\n.ProgressBarStyles_counter__MHhvu {\n    font-size: 0.6rem;\n    color: #BABABA;\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/UX/ProgressBar/ProgressBarStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".progressBarContainer {\n    display: flex;\n    align-items: center;\n    gap: 0.2rem;\n}\n\n.progressBar {\n    position: relative;\n    border-radius: 4px;\n    overflow: hidden;\n    background-color: white;\n    border: 2px solid;\n}\n\n.progressIndicator {\n    height: 100%;\n    text-align: center;\n    font-weight: bold;\n    transition: width 0.3s;\n}\n\n.counter {\n    font-size: 0.6rem;\n    color: #BABABA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarContainer": "ProgressBarStyles_progressBarContainer__qRPGr",
	"progressBar": "ProgressBarStyles_progressBar__ZGFin",
	"progressIndicator": "ProgressBarStyles_progressIndicator__8ZLjO",
	"counter": "ProgressBarStyles_counter__MHhvu"
};
export default ___CSS_LOADER_EXPORT___;
