import {ReactElement, useEffect, useMemo} from 'react';
import {createPortal} from "react-dom";

const modalRootElement = document.querySelector('#modal')

export const Portal = (props: {children: ReactElement}) => {

    const element = useMemo(() => {
        return document.createElement('div')
    }, [])

    element.style.overflowY = 'scroll'

    useEffect(() => {
        if (modalRootElement)
            modalRootElement.appendChild(element)

        return () => {
            if (modalRootElement)
                modalRootElement.removeChild(element)
        }
    }, [])

    return createPortal(
        props.children,
        element
    );
};