import {AppInfoIn} from "../../common/types";
import {$authHost} from "../../axios";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getLanguage = createAsyncThunk(
    'info/fetch',
    async (_, thunkAPI) => {
        try {
            const response = await $authHost.get<AppInfoIn>(`/card_sorting/info`)
            return response.data
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e.message)
        }
    }
)