// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmModalStyles_modalText__Ijl50 {\n    max-width: 56.25rem;\n    min-width: 6.25rem;\n    margin-bottom: 1.25rem;\n    text-align: center;\n    font-size: 1.25rem;\n}\n\n.ConfirmModalStyles_buttonsContainer__ZmKnz {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    gap: 2rem\n}\n\n@media (max-width: 1024px) {\n    .ConfirmModalStyles_modalText__Ijl50 {\n        max-width: 15.625rem;\n    }\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/ConfirmModal/ConfirmModalStyles.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB;AACJ;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".modalText {\n    max-width: 56.25rem;\n    min-width: 6.25rem;\n    margin-bottom: 1.25rem;\n    text-align: center;\n    font-size: 1.25rem;\n}\n\n.buttonsContainer {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    gap: 2rem\n}\n\n@media (max-width: 1024px) {\n    .modalText {\n        max-width: 15.625rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalText": "ConfirmModalStyles_modalText__Ijl50",
	"buttonsContainer": "ConfirmModalStyles_buttonsContainer__ZmKnz"
};
export default ___CSS_LOADER_EXPORT___;
