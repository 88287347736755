import {combineReducers, configureStore} from "@reduxjs/toolkit";
import appReducer from './appReducer/appReducer'
import cardSortingReducer from "../project/modules/main/CardSorting/reducers/cardSortingReducer/cardSortingReducer";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import usersReducer from "../project/modules/main/CardSortingAdmin/reducers/usersReducer";

const rootReducer = combineReducers({
    appReducer,
    cardSortingReducer,
    usersReducer
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector